<template>
    <v-dialog v-model="isDialogOpen" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Assign Details</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">
                        <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-form ref="form">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <p>Available {{ this.currentRole === 'tour-guide' ? 'tour guide' : this.currentRole }}:
                                    {{ dntData.length }}</p>
                                <p>Selected Transfers: {{ assignIds.length }}</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete :items="dntData" item-title="full_name" item-value="uuid"
                                    label="Select Driver/Tour Guide" v-model="dntId"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-btn class="button-1 w-100" :disabled="dntId == '' || assignIds.length == 0"
                                    @click="submitRequest" :loading="isLoading">Request</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>Job Detail</h1>
                <p>Dashboard > Calendar > Job</p>
                <div class="job-infomation">
                    <div class="button-1" v-if="data && data.data && data.data.stays && data.data.stays.length == 0">
                        Priority</div>
                    <div class="button-1" v-else>
                        <p>Standard</p>
                    </div>
                    <v-btn href="" color="error" prepend-icon="mdi-delete" :loading="isLoading">Delete Job</v-btn>
                </div>
            </div>

            <div class="user-details container">
                <div v-if="data && data.data && data.data.tourist">
                    <div class="user">
                        <div class="image-container">
                            <Image :imagePath="data.data.tourist.image_path"></Image>
                        </div>
                        <div class="details">
                            <h1 class="name">{{ data.data.tourist.first_name + ' ' + data.data.tourist.last_name }}</h1>
                            <p>+{{ data.data.tourist.contact_numbers.find((a) => a.primary ===
        1).country_code.country_code }}{{
        data.data.tourist.contact_numbers.find((a) => a.primary === 1).contact_number }}</p>
                        </div>
                    </div>
                    <div class="contacts">
                        <a href="" @click.prevent="$router.push('/chats/' + data.data.tourist.uuid)" class="chat">
                            <img src="@/assets/icons/chat.svg" alt="chat.svg">
                        </a>
                        <a href="" @click.prevent="sendWhatsapp(data.data.tourist.contact_numbers.find((a) => a.primary ===
        1).country_code.country_code +
        data.data.tourist.contact_numbers.find((a) => a.primary === 1).contact_number)" class="whatsapp">
                            <img src="@/assets/icons/whatsapp.svg" alt="whatsapp.svg">
                        </a>
                    </div>
                </div>
                <div v-else>
                    <p>Loading...</p>
                </div>
            </div>

            <div class="stay-pax-details">
                <div class="stay-details container">
                    <div class="icon-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28.873" height="22.676"
                            viewBox="0 0 15.873 22.676">
                            <path id="Icon_material-location-on" data-name="Icon material-location-on"
                                d="M15.437,3A7.931,7.931,0,0,0,7.5,10.937c0,5.953,7.937,14.74,7.937,14.74s7.937-8.787,7.937-14.74A7.931,7.931,0,0,0,15.437,3Zm0,10.771a2.835,2.835,0,1,1,2.835-2.835A2.836,2.836,0,0,1,15.437,13.771Z"
                                transform="translate(-7.5 -3)" fill="currentColor" />
                        </svg>

                        <p>Stay</p>
                    </div>

                    <div class="content">
                        <div v-if="data && data.data && data.data.stays && data.data.stays.length > 0">
                            <div v-for="(stay, index) in data.data.stays" :key="index">
                                <p>{{ formatDateFull(stay.start_date) }} - {{
        formatDateFull(stay.end_date) }} @ {{
        stay.hotel.main_text || 'No Location' }}</p>
                            </div>
                        </div>
                        <div v-else>
                            <p>No stay detail available</p>
                        </div>
                    </div>

                    <div class="icon-wrapper">
                        <v-btn density="compact" icon="mdi-pencil"></v-btn>
                    </div>
                </div>

                <div class="pax-details container">
                    <div class="icon-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28.768" height="18.493"
                            viewBox="0 0 28.768 18.493">
                            <path id="Icon_ionic-md-people" data-name="Icon ionic-md-people"
                                d="M21.864,15.58a3.853,3.853,0,1,0-3.923-3.853A3.9,3.9,0,0,0,21.864,15.58Zm-10.461,0A3.853,3.853,0,1,0,7.48,11.728,3.9,3.9,0,0,0,11.4,15.58Zm0,2.825c-3.073,0-9.153,1.477-9.153,4.495v3.467H20.743V22.9C20.743,19.883,14.476,18.406,11.4,18.406Zm10.461.707a6.979,6.979,0,0,0-1.121.064c1.5,1.092,2.055,1.8,2.055,3.724v3.467h8.219V22.9C31.017,19.883,24.937,19.112,21.864,19.112Z"
                                transform="translate(-2.25 -7.875)" fill="currentColor" />
                        </svg>

                        <p>Guest</p>
                    </div>

                    <div class="content"
                        v-if="data && data.data && ('no_of_adults' in data.data) && ('no_of_childs' in data.data) && ('no_of_infants' in data.data)">
                        <p>Adults: {{ data.data.no_of_adults }}</p>
                        <p>Childs: {{ data.data.no_of_childs }}</p>
                        <p>Infants: {{ data.data.no_of_infants }}</p>
                    </div>

                    <div class="icon-wrapper">
                        <v-btn density="compact" icon="mdi-pencil"></v-btn>
                    </div>
                </div>
            </div>

            <div class="priority-details" v-if="data && data.data && data.data.priority">
                <div class="container">
                    <div class="priority-details">
                        <p class="mt-0"><strong>Priority Details</strong></p>
                        <p class="mt-0"><v-icon class="mr-4">mdi-calendar</v-icon> Type: <strong>{{
        data.data.priority.type
    }}</strong></p>
                        <p class="mt-1"><v-icon class="mr-4">mdi-signal</v-icon> Ping: <strong>{{
            data.data.priority.ping.ping_name }}</strong>
                        </p>
                        <p class="mt-1"><v-icon class="mr-4">mdi-cash</v-icon> Payment Type: <strong>{{
        data.data.priority.payment_type.type_name }}</strong>
                        </p>
                        <p class="mt-1"><v-icon class="mr-4">mdi-car</v-icon> Vehicle Type: <strong>{{
        data.data.priority.vehicle_type.type_name }}</strong>
                        </p>
                        <p class="mt-1"><v-icon class="mr-4">mdi-currency-usd</v-icon> Job Cost: <strong>${{
        data.data.priority.job_cost
    }}</strong></p>
                    </div>
                </div>
                <div class="container timer">
                    <v-icon class="mb-2">mdi-clock-outline</v-icon>
                    <p>Searching for drivers...</p>
                </div>
            </div>


            <div class="transfer-container" v-if="groupedTransfers && groupedTransfers.length > 0">
                <div v-for="(group, index) in groupedTransfers" :key="index">
                    <div class="transfer-header">
                        <h3>{{ this.$moment(group.date).format('DD MMM yyyy') }}</h3>
                    </div>

                    <v-data-table :headers="headers" :items="group.transfers" :items-per-page="-1"
                        :sort-by="[{ key: 'pick_up_time', order: 'asc' }]" disable-pagination hide-default-footer
                        item-key="uuid">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ $moment(item.pick_up_time).format('HH:mm') }}</td>
                                <td>{{ item.transfer_type_name }}</td>
                                <td>{{ item.transfer_type.transfer_type_special_slug }}</td>
                                <td>{{ item.from.main_text }}</td>
                                <td>{{ item.to.main_text }}</td>
                                <td>{{ item.remarks ?? '-' }}</td>
                                <td>
                                    <DriverAssignmentCard :is-priority-job="data.data.priority !== null" role="driver"
                                        :is-selected="assignIds.includes(item.uuid)" :current-role="currentRole"
                                        :assigned-job-request-details="item.assigned_job_request_details"
                                        :attendances="item.attendances ?? []"
                                        @assign="handleCheckboxClick(item.uuid, $event)" />
                                </td>
                                <td>
                                    <DriverAssignmentCard :is-priority-job="data.data.priority !== null"
                                        role="contractor" :is-selected="assignIds.includes(item.uuid)"
                                        :current-role="currentRole"
                                        :assigned-job-request-details="item.assigned_job_request_details"
                                        @assign="handleCheckboxClick(item.uuid, $event)" />
                                </td>
                                <td>
                                    <DriverAssignmentCard :is-priority-job="data.data.priority !== null"
                                        role="tour-guide" :is-selected="assignIds.includes(item.uuid)"
                                        :current-role="currentRole"
                                        :assigned-job-request-details="item.assigned_job_request_details"
                                        @assign="handleCheckboxClick(item.uuid, $event)" />
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </div>

            <div v-if="assignIds.length > 0" class="transfer-assign-shortcut d-flex align-center">
                <v-btn prepend-icon="mdi-close" color="error" @click="resetAssignIds()" class="mr-3">
                    Unselect
                </v-btn>
                <v-btn prepend-icon="mdi-check-circle-outline" class="button-1" @click="openDialog">
                    Assign Transfer
                </v-btn>
            </div>

            <v-dialog v-model="returnTrip" max-width="400" persistent>
                <v-card prepend-icon="mdi-map-marker"
                    :text="returnTripQueue.length > 0 ? returnTripQueue.length + ' return pick-ups detected. Do you want to select?' : 'Return pick-up detected. Do you want to select?'"
                    title="Return Pick Up?">
                    <template v-slot:actions>
                        <v-spacer></v-spacer>

                        <v-btn @click="this.returnTrip = false">
                            No
                        </v-btn>

                        <v-btn @click="returnTripQueue.length > 0 ? processReturnTrips() : assignReturnTrip()">
                            Yes
                        </v-btn>
                    </template>
                </v-card>
            </v-dialog>

            <div class="pdf-container" v-if="data && data.data.priority === null">
                <iframe :src="pdfData" frameborder="0" v-if="pdfData"></iframe>
                <div v-else>
                    Loading PDF...
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import TopNav from '@/components/TopNav.vue'
import Image from '@/components/Image.vue';
import DriverAssignmentCard from '@/components/AssignmentCard.vue';
import { toast } from 'vue3-toastify';
import db from '@/databases/indexedDB'
import { liveQuery } from 'dexie';
import { httpGet, httpPost } from '@/services/http';

export default {
    name: 'JobDetailView',
    components: {
        TopNav,
        Image,
        DriverAssignmentCard
    },
    data() {
        return {
            id: this.$route.params.id,
            isLoading: false,
            assignIds: [],
            isShiftKeyPressed: false,
            returnTripQueue: [],
            returnTrip: false,
            dntData: [],
            dntId: '',
            data: null,
            groupedTransfers: [],
            flattenedTransfers: [],
            pdfData: null,
            isDialogOpen: false,
            subscription: null,
            currentRole: null,
            headers: [
                { title: 'Time', key: 'pick_up_time', sortable: false },
                { title: 'Transfer Type', key: 'transfer_type', sortable: false },
                { title: 'Slug', key: 'transfer_type_special.transfer_type_special_slug', sortable: false },
                { title: 'From', key: 'from.main_text', sortable: false },
                { title: 'To', key: 'to.main_text', sortable: false },
                { title: 'Remarks', key: 'remarks', sortable: false },
                { title: 'Driver', key: 'driver', sortable: false },
                { title: 'Contractor', key: 'contractor', sortable: false },
                { title: 'Tour Guide', key: 'tour_guide', sortable: false },
            ],
        }
    },
    created() {
        this.fetchJobDetails();
        this.fetchAndDisplayPDF();
        this.fetchDtcData();

        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyUp);
    },
    beforeUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    beforeDestroy() {
        // ... existing code ...
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
    },
    computed: {
        flattenedTransfers() {
            return this.groupedTransfers.flatMap((group, groupIndex) =>
                group.transfers.map((transfer, transferIndex) => ({
                    ...transfer,
                    index: `${groupIndex + 1}.${transferIndex + 1}`
                }))
            );
        }
    },
    methods: {
        async fetchDtcData() {
            const observableData = liveQuery(async () => {
                const dtcRequests = await db.dtc_requests.orderBy('created_at').reverse().toArray();
                const users = await db.users.toArray();
                const dtcRequestDetails = await db.dtc_request_details.toArray();

                const userMap = new Map(users.map(user => [user.uuid, user]));

                return dtcRequests.map(request => {
                    const requester = userMap.get(request.requester_uuid) || null;
                    const user = userMap.get(request.user_uuid) || null;
                    const details = dtcRequestDetails.filter(detail => detail.dtc_request_uuid === request.uuid);

                    return {
                        ...request,
                        requester,
                        user,
                        details,
                    };
                });
            });

            this.subscription = observableData.subscribe(result => {
                this.dtcData = result
                    .filter(a => a.status === 'Accepted' && !a.deleted_at)
                    .map(a => ({
                        ...a,
                        full_name: `${a.user.first_name} ${a.user.last_name} (${a.user.role.role_name})`
                    }));
                this.filterDTCData();
            });
        },
        async fetchJobDetails() {
            const observableData = liveQuery(async () => {
                const job = await db.jobs.get(this.id);
                if (!job) return null;

                const transfers = await db.transfers.where('job_uuid').equals(job.uuid).toArray();
                const users = await db.users.toArray();
                const transferTypes = await db.transfer_types.toArray();
                const locations = await db.locations.toArray();
                const locationDetails = await db.location_details.toArray();
                const assignedJobRequests = await db.assigned_job_requests.toArray();
                const assignedJobRequestDetails = await db.assigned_job_request_details.toArray();
                const stays = await db.stays.where('job_uuid').equals(job.uuid).toArray();

                const userMap = new Map(users.map(user => [user.uuid, user]));
                const transferTypeMap = new Map(transferTypes.map(type => [type.uuid, type]));
                const locationMap = new Map(locations.map(location => [location.uuid, location]));
                const locationDetailMap = new Map(locationDetails.map(detail => [detail.uuid, detail]));
                const requestMap = new Map(assignedJobRequests.map(request => [request.uuid, request]));

                const tourist = userMap.get(job.tourist_uuid) || {};

                const transfersWithDetails = transfers.map(transfer => {
                    const transferTypeSpecial = transferTypeMap.get(transfer.transfer_type_special_uuid) || {};
                    const fromLocation = locationMap.get(transfer.from_uuid) || {};
                    const toLocation = locationMap.get(transfer.to_uuid) || {};
                    const fromLocationDetail = locationDetailMap.get(fromLocation.detail_uuid) || {};
                    const toLocationDetail = locationDetailMap.get(toLocation.detail_uuid) || {};

                    const assignedJobDetails = assignedJobRequestDetails
                        .filter(detail => detail.transfer_uuid === transfer.uuid)
                        .map(detail => {
                            const request = requestMap.get(detail.assigned_job_request_uuid) || {};
                            const requestUser = userMap.get(request.user_uuid) || {};
                            return { ...detail, assigned_job_request: { ...request, user: requestUser } };
                        });

                    return {
                        ...transfer,
                        tourist,
                        id: job.uuid,
                        no_of_adults: job.no_of_adults,
                        no_of_childs: job.no_of_childs,
                        no_of_infants: job.no_of_infants,
                        priority: job.priority,
                        transfer_type: transfer.transfer_type_name,
                        transfer_type_special: transferTypeSpecial,
                        from: { ...fromLocation, detail: fromLocationDetail },
                        to: { ...toLocation, detail: toLocationDetail },
                        assigned_job_request_details: assignedJobDetails
                    };
                });

                const staysWithHotelDetails = stays.map(stay => {
                    const hotelLocation = locationMap.get(stay.hotel_uuid) || {};
                    return {
                        ...stay,
                        hotel: hotelLocation
                    };
                });

                return {
                    data: {
                        ...job,
                        tourist,
                        transfers: transfersWithDetails,
                        stays: staysWithHotelDetails
                    }
                };
            });

            this.subscription = observableData.subscribe(result => {
                this.data = result;
                this.groupTransfers();
            });
        },
        groupTransfers() {
            if (this.data && this.data.data && this.data.data.transfers) {
                const transfers = this.data.data.transfers;
                const groups = {};
                transfers.forEach(transfer => {
                    const date = this.formatDate(transfer.pick_up_time);
                    if (!groups[date]) {
                        groups[date] = [];
                    }
                    groups[date].push(transfer);
                });
                Object.keys(groups).forEach(date => {
                    groups[date].sort((a, b) => new Date(a.pick_up_time) - new Date(b.pick_up_time));
                });
                const sortedGroups = Object.keys(groups).sort((a, b) => new Date(a) - new Date(b));
                this.groupedTransfers = sortedGroups.map(date => ({
                    date,
                    transfers: groups[date]
                }));
            } else {
                this.groupedTransfers = [];
            }
        },
        formatDate(date) {
            const d = new Date(date);
            const day = d.getDate().toString().padStart(2, "0");
            const month = (d.getMonth() + 1).toString().padStart(2, "0");
            const year = d.getFullYear();

            return `${day}/${month}/${year}`;
        },
        formatTime(dateTimeString) {
            const date = new Date(dateTimeString);
            const options = { hour: 'numeric', minute: 'numeric', hour12: false };
            return date.toLocaleTimeString('en-US', options);
        },
        sendWhatsapp(number) {
            const formattedNumber = number.toString().replace(/[\s-()+]/g, '');
            window.open(`https://api.whatsapp.com/send?phone=${formattedNumber}`, '_blank').focus();
        },
        handleKeyDown(event) {
            if (event.key === 'Shift') {
                this.isShiftKeyPressed = true;
            }
        },

        handleKeyUp(event) {
            if (event.key === 'Shift') {
                this.isShiftKeyPressed = false;
                const unassignedReturnTrips = this.returnTripQueue.filter(trip => !this.assignIds.includes(trip.uuid));
                if (unassignedReturnTrips.length > 0) {
                    this.returnTrip = true; // Show dialog
                } else {
                    this.returnTripQueue = [];
                }
            }
        },

        processReturnTrips() {
            if (this.returnTripQueue.length > 0) {
                this.returnTripQueue.forEach(trip => {
                    this.assignIds.push(trip.uuid);
                });
                this.returnTripQueue = [];
            }
            this.returnTrip = false;
        },

        resetAssignIds() {
            this.assignIds = [];
            this.currentRole = null;
            this.filterDTCData();
        },

        // Update the existing handleCheckboxClick method
        handleCheckboxClick(transferId, role) {
            const index = this.assignIds.indexOf(transferId);
            if (index === -1) {
                this.assignIds.push(transferId);
                this.currentRole = role;
                this.filterDTCData();

                let selectedTransfer = this.flattenedTransfers.find((a) => a.uuid === transferId);
                if (selectedTransfer) {
                    let returnTrip = this.flattenedTransfers.find((a) =>
                        a.to && a.from && selectedTransfer.from && selectedTransfer.to && a.tourist &&
                        a.to.uuid === selectedTransfer.from.uuid &&
                        a.from.uuid === selectedTransfer.to.uuid &&
                        a.tourist.uuid === selectedTransfer.tourist.uuid &&
                        this.isSameDate(a.pick_up_time, selectedTransfer.pick_up_time)
                    );

                    if (returnTrip && !this.assignIds.includes(returnTrip.uuid)) {
                        if (this.isShiftKeyPressed) {
                            this.returnTripQueue.push(returnTrip);
                        } else {
                            this.returnTrip = returnTrip.uuid;
                        }
                    }
                }
            } else {
                this.assignIds.splice(index, 1);
                if (this.assignIds.length === 0) {
                    this.currentRole = null;
                    this.filterDTCData();
                }
            }
        },

        isSameDate(date1, date2) {
            const d1 = new Date(date1);
            const d2 = new Date(date2);
            return (
                d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() &&
                d1.getDate() === d2.getDate()
            );
        },

        assignReturnTrip() {
            if (this.returnTrip) {
                this.assignIds.push(this.returnTrip);
                this.returnTrip = false;
            }
        },
        openDialog() {
            this.isDialogOpen = true;
        },
        closeDialog() {
            this.isDialogOpen = false;
        },
        async submitRequest() {
            const data = { id: this.dntId, transfer_ids: this.assignIds };
            const id = toast.loading('Please wait...');
            this.isLoading = true;
            try {
                await httpPost('/api/v1/travel_agents/jobs/assigns/add', data);
                this.dntId = null;
                this.currentRole = null;
                this.assignIds = [];
                toast.update(id, {
                    render: "Request submitted successfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 3000
                });
                this.isLoading = false;
                this.closeDialog();
                this.fetchJobDetails();
            } catch (error) {
                console.error(error);
                toast.update(id, {
                    render: "An error occurred",
                    type: "error",
                    isLoading: false,
                    autoClose: 3000
                });
                this.isLoading = false;
            }
        },
        async fetchAndDisplayPDF() {
            const config = {
                responseType: 'blob', // Important for PDFs
            }

            await httpGet('/api/v1/travel_agents/jobs/pdf/' + this.id, config)
                .then(response => {
                    // Convert the Blob into a Data URL
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        this.pdfData = reader.result; // This will be a Data URL
                        // Now you can pass this.pdfData to VuePDF as the src
                        console.log('data ready', this.pdfData)
                    };
                    reader.readAsDataURL(response.data); // Read the blob as Data URL
                    console.log('/api/v1/travel_agents/jobs/pdf/' + this.id, this.pdfData)
                }).catch(error => {
                    console.error(error)
                })
        },
        fetchJobDetails() {
            const observableData = liveQuery(async () => {
                const job = await db.jobs.get(this.id);
                if (!job) return null;

                const tourist = await db.users.get(job.tourist_uuid);
                const transfers = await db.transfers.where('job_uuid').equals(job.uuid).toArray();
                const stays = await db.stays.where('job_uuid').equals(job.uuid).toArray();

                // Fetch hotel locations for stays
                const staysWithHotelDetails = await Promise.all(stays.map(async stay => {
                    const hotelLocation = await db.locations.get(stay.hotel_uuid);
                    return {
                        ...stay,
                        hotel: hotelLocation
                    };
                }));

                // Fetch related data for transfers
                const transfersWithDetails = await Promise.all(transfers.map(async transfer => {
                    const from = await db.locations.get(transfer.from_uuid);
                    const to = await db.locations.get(transfer.to_uuid);
                    const transferType = await db.transfer_types.get(transfer.transfer_type_special_uuid);
                    const assignedJobRequestDetails = await db.assigned_job_request_details
                        .where('transfer_uuid').equals(transfer.uuid).toArray();

                    const assignedJobRequests = await Promise.all(assignedJobRequestDetails.map(async detail => {
                        const request = await db.assigned_job_requests.get(detail.assigned_job_request_uuid);
                        const user = request ? await db.users.get(request.user_uuid) : null;
                        return {
                            ...detail,
                            assigned_job_request: {
                                ...request,
                                user
                            }
                        };
                    }));

                    return {
                        ...transfer,
                        from,
                        to,
                        transfer_type: transferType,
                        assigned_job_request_details: assignedJobRequests
                    };
                }));

                return {
                    data: {
                        ...job,
                        tourist,
                        transfers: transfersWithDetails,
                        stays: staysWithHotelDetails
                    }
                };
            });

            this.subscription = observableData.subscribe(result => {
                this.data = result;
            });
        },
        filterDTCData() {
            if (this.currentRole) {
                this.dntData = this.dtcData.filter(a => a.user.role.role_slug === this.currentRole);
            } else {
                this.dntData = [...this.dtcData];
            }
        },
        formatDate(date) {
            const d = new Date(date);
            const day = d.getDate().toString().padStart(2, "0");
            const month = (d.getMonth() + 1).toString().padStart(2, "0");
            const year = d.getFullYear();

            return `${day}/${month}/${year}`;
        },
        formatDateFull(date) {
            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            const d = new Date(date);
            const day = d.getDate();
            const month = months[d.getMonth()];
            const year = d.getFullYear();
            return `${day} ${month} ${year}`;
        },
        isSameDate(date1, date2) {
            const d1 = new Date(date1);
            const d2 = new Date(date2);
            return (
                d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() &&
                d1.getDate() === d2.getDate()
            );
        },
        assignReturnTrip() {
            if (this.returnTrip) {
                this.assignIds.push(this.returnTrip);
                this.returnTrip = false;
            }
        },
        formatTime(dateTimeString) {
            const date = new Date(dateTimeString);
            const options = { hour: 'numeric', minute: 'numeric', hour12: false };
            return date.toLocaleTimeString('en-US', options);
        },
        sendWhatsapp(number) {
            const formattedNumber = number.toString().replace(/[\s-()+]/g, '');
            window.open(`https://api.whatsapp.com/send?phone=${formattedNumber}`, '_blank').focus();
        },
        openDialog() {
            this.isDialogOpen = true;
        },
        closeDialog() {
            this.isDialogOpen = false;
        },
    },
    computed: {
        groupedTransfers() {
            if (this.data && this.data.data && this.data.data.transfers) {
                const transfers = this.data.data.transfers;
                const groups = {};
                transfers.forEach(transfer => {
                    const date = this.formatDate(transfer.pick_up_time);
                    if (!groups[date]) {
                        groups[date] = [];
                    }
                    groups[date].push(transfer);
                });
                Object.keys(groups).forEach(date => {
                    groups[date].sort((a, b) => new Date(a.pick_up_time) - new Date(b.pick_up_time));
                });
                const sortedGroups = Object.keys(groups).sort((a, b) => new Date(a) - new Date(b));
                return sortedGroups.map(date => ({
                    date,
                    transfers: groups[date]
                }));
            } else {
                return [];
            }
        }
    }
}
</script>

<style src="@/assets/css/index.css" scoped></style>
<style src="@/assets/css/job.css" scoped></style>
<style scoped>
.job-infomation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.job-infomation .button-1 {
    margin-top: unset !important;
}

.user .image-container {
    margin-right: 20px;
}

.priority-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.timer {
    margin-top: unset !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.timer h3 {
    font-size: 2.5em;
}

@media screen and (max-width: 850px) {
    .priority-details {
        grid-template-columns: repeat(1, 1fr);
    }
}
</style>
