<template>
    <div class="live-location" v-if="showSideBar & isLogin & notMapPage">
        <form action="" method="post">
            <div class="search-field">
                <img src="@/assets/icons/search.svg" alt="search">
                <input type="search" name="" id="" class="form-control" placeholder="Search">
            </div>
        </form>
        <div class="title">
            <h3>Live Locations</h3>
            <p>View all active driver real-time location</p>
        </div>
        <div class="map" @click.prevent="this.$router.push('/')">
            <GMapMap :center="center" :zoom="10" map-type-id="terrain" :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: mapStyles
    }">
            </GMapMap>
        </div>
        <!-- <v-card elevation="0" class="mt-5">
            <v-tabs v-model="tab" color="primary">
                <v-tab value="one">Your Drivers</v-tab>
                <v-tab value="two">Today's Jobs</v-tab>
            </v-tabs>

            <v-card-text class="pa-0">
                <v-tabs-window v-model="tab" class="mt-3">
                    <v-tabs-window-item value="one">
                        <div class="drivers">
                            <div class="driver">
                                <div class=" driver-details">
                                    <div class="image-container">
                                        <Image :imagePath="request.transfer_type_special_request.user.image_path">
                                        </Image>
                                    </div>
                                    <div class="details">
                                        <p class="name">Guest</p>
                                        <p>Driver</p>
                                    </div>
                                </div>
                                <div class="trip-details">
                                    <span>
                                        <img src="@/assets/icons/single-person.svg" alt="person">
                                        <p>3</p>
                                    </span>
                                    <span>
                                        <img src="@/assets/icons/location.svg" alt="location">
                                        <p>2</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </v-tabs-window-item>

                    <v-tabs-window-item value="two">
                        <div class="drivers">
                            <div class="driver">
                                <div class=" driver-details">
                                    <div class="image-container">
                                      <Image :imagePath="request.transfer_type_special_request.user.image_path">
                                        </Image> 
    </div>
    <div class="details">
        <p class="name">Guest</p>
        <p>Driver</p>
    </div>
    </div>
    <div class="trip-details">
        <span>
            <img src="@/assets/icons/single-person.svg" alt="person">
            <p>3</p>
        </span>
        <span>
            <img src="@/assets/icons/location.svg" alt="location">
            <p>2</p>
        </span>
    </div>
    </div>
    </div>
    </v-tabs-window-item>
    </v-tabs-window>
    </v-card-text>
    </v-card> -->
        <div class="drivers mt-5" v-if="todaysJobs.length > 0">
            <div class="driver" v-for="request in todaysJobs"
                @click.prevent="this.$router.push('/requests/' + request.uuid)">
                <div class=" driver-details">
                    <div class="image-container">
                        <Image :imagePath="request.transfer_type_special_request.user.image_path">
                        </Image>
                    </div>
                    <div class="details">
                        <p class="name">{{ request.transfer_type_special_request.user.first_name + ' ' +
        request.transfer_type_special_request.user.last_name }}</p>
                        <p>Driver</p>
                    </div>
                </div>
                <div class="trip-details">
                    <span>
                        <img src="@/assets/icons/single-person.svg" alt="person">
                        <p>{{ request.distinctGuests.size }}</p>
                    </span>
                    <span>
                        <img src="@/assets/icons/location.svg" alt="location">
                        <p>{{ request.details.length }}</p>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import map from '@/assets/styles/map'
import Image from '@/components/Image.vue';
import { httpGet } from '@/services/http';
import { mapStores, mapState } from 'pinia'
import { useSideBarStore } from '@/stores/SideBarStore'
import { useAuthStore } from '@/stores/AuthStore'

export default
    {
        name: 'LiveLocation',
        components: {
            Image
        },
        data() {
            return {
                tab: 0,
                mapStyles: JSON.parse(map.customMapStyles),
                center: { lat: 1.290270, lng: 103.851959 },
                todaysJobs: [],
            }
        },
        computed: {
            notMapPage() {
                return this.$route.path === '/map' ? false : true;
            },
            ...mapStores(useSideBarStore),
            ...mapStores(useAuthStore),
            ...mapState(useSideBarStore, ['showSideBar']),
            ...mapState(useAuthStore, ['isLogin']),
        },
        mounted() {
            if (this.isLogin) {
                console.log(this.mapStyles)
                console.log('map loaded')
                this.getAllActiveJobs()
            }
        },
        methods: {
            consolidateGuestNames(details) {
                const guests = new Set();
                details.forEach(detail => {
                    // Assuming each detail has a guest information
                    guests.add(detail.transfer.job.tourist);
                });
                return guests; // Converts Set to Array and joins names with comma
            },
            getAllActiveJobs() {
                httpGet('/api/v1/travel_agents/jobs/assigns/actives')
                    .then(response => {
                        if (response.data.data) {
                            this.activeJobs = response.data.data;
                            this.todaysJobs = this.getActiveJobsToday(response.data.data)
                            console.log(this.activeJobs)
                            console.log(this.todaysJobs)
                            // this.todaysJobs = response.data.data;
                            this.todaysJobs.forEach((request) => {
                                request.distinctGuests = this.consolidateGuestNames(request.details);
                            })
                        }
                    })
                    .catch(error => {
                        console.error('Failed to load data:', error);
                    });
            },
            getActiveJobsToday(data) {
                const todayJobs = new Set();  // Using a Set to avoid duplicates

                data.forEach(item => {
                    item.details.forEach(detail => {
                        const jobDate = new Date(detail.transfer.pick_up_time);

                        if (this.isToday(jobDate)) {
                            todayJobs.add(item);
                        }
                    });
                });

                return Array.from(todayJobs);
            },
            isToday(date) {
                const today = new Date();
                return date.getDate() === today.getDate() &&
                    date.getMonth() === today.getMonth() &&
                    date.getFullYear() === today.getFullYear();
            },
        }
    }
</script>

<style>
.vue-map-container {
    height: 100%;
}
</style>