<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="content-wrapper">
                <a href="" class="logo">
                    <img src="@/assets/logo/Main_1.png" alt="logo" class="full-logo">
                </a>

                <div class="title pa-1">
                    <h1>Please wait...</h1>
                    <p>Authenticating...</p>
                </div>
            </div>


        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import { mapActions, mapState } from 'pinia'
import { useAuthStore } from '@/stores/AuthStore'

export default {
    name: 'HandleThirdPartyView',
    components: {
        TopNav,
    },
    data() {
        return {
            isLoading: false,
        }
    },
    computed: {
        ...mapState(useAuthStore, ['isLogin', 'profile']),
    },
    mounted() {
        this.checkQueryParams();
    },
    methods: {
        async checkQueryParams() {
            const { provider, code } = this.$route.query;
            this.$router.replace({ 'query': null });
            if (provider && code) {
                if (!this.isLogin) {
                    this.$router.push({ path: '/login', query: { provider, code } });
                } else {
                    this.$router.push({ path: '/profile/general/edit', query: { provider, code } });
                }
            } else {
                this.$router.push('/login');
            }
        },
    }
}
</script>

<style scoped>
section#content .content-wrapper .logo {
    width: 40%;
    margin: 0px auto;
}

section#content .content-wrapper .logo img {
    margin-top: 50px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

section#content .content-wrapper {
    max-width: 50%;
    margin: 20% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

@media screen and (max-width: 950px) {
    section#content .content-wrapper {
        max-width: unset;
    }
}
</style>