<template>
    <div class="top-nav" ref="topNav">
        <div class="nav-toggle" @click="toggleNav()" :class="{ 'scrolled': isScrolled }">
            <svg xmlns="http://www.w3.org/2000/svg" width="28.155" height="19.436" viewBox="0 0 28.155 19.436">
                <line id="Line_1" data-name="Line 1" x2="23" transform="translate(1 1)" fill="none"
                    stroke="currentColor" stroke-linecap="round" stroke-width="2" />
                <line id="Line_1-2" data-name="Line 1" x2="23" transform="translate(1 9.718)" fill="none"
                    stroke="currentColor" stroke-linecap="round" stroke-width="2" />
                <line id="Line_1-3" data-name="Line 1" x2="23" transform="translate(1 18.436)" fill="none"
                    stroke="currentColor" stroke-linecap="round" stroke-width="2" />
            </svg>
        </div>
        <h3>{{ title }}</h3>
        <div class="buttons">
            <router-link to="/search">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.861" height="19.861" viewBox="0 0 19.861 19.861">
                    <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(1 1)">
                        <path id="Path_475" data-name="Path 475"
                            d="M20.009,12.254A7.754,7.754,0,1,1,12.254,4.5,7.754,7.754,0,0,1,20.009,12.254Z"
                            transform="translate(-4.5 -4.5)" fill="none" stroke="#353744" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2" />
                        <path id="Path_476" data-name="Path 476" d="M29.191,29.191l-4.216-4.216"
                            transform="translate(-11.744 -11.744)" fill="none" stroke="#353744" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2" />
                    </g>
                </svg>
            </router-link>
            <router-link to="/notifications">
                <v-badge color="error" dot>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                        <title>notification_line</title>
                        <g id="notification_line" fill='none' fill-rule='evenodd'>
                            <path
                                d='M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5935,23.2578 L12.5819,23.2595 L12.5109,23.295 L12.4919,23.2987 L12.4767,23.295 L12.4057,23.2595 C12.3958,23.2564 12.387,23.259 12.3821,23.2649 L12.378,23.2758 L12.3609,23.7031 L12.3659,23.7235 L12.3769,23.7357 L12.4805,23.8097 L12.4953,23.8136 L12.5071,23.8097 L12.6107,23.7357 L12.6233,23.7197 L12.6267,23.7031 L12.6096,23.2758 C12.6076,23.2657 12.601,23.2593 12.5935,23.2578 Z M12.8584,23.1453 L12.8445,23.1473 L12.6598,23.2397 L12.6499,23.2499 L12.6472,23.2611 L12.6651,23.6906 L12.6699,23.7034 L12.6784,23.7105 L12.8793,23.8032 C12.8914,23.8069 12.9022,23.803 12.9078,23.7952 L12.9118,23.7812 L12.8777,23.1665 C12.8753,23.1546 12.8674,23.147 12.8584,23.1453 Z M12.143,23.1473 C12.1332,23.1424 12.1222,23.1453 12.1156,23.1526 L12.1099,23.1665 L12.0758,23.7812 C12.0751,23.7927 12.0828,23.8019 12.0926,23.8046 L12.1083,23.8032 L12.3092,23.7105 L12.3186,23.7024 L12.3225,23.6906 L12.3404,23.2611 L12.3372,23.2485 L12.3278,23.2397 L12.143,23.1473 Z' />
                            <path fill='currentColor'
                                d='M5.00016,9 C5.00016,5.13401 8.13417,2 12.0002,2 C15.8662,2 19.0002,5.13401 19.0002,9 L19.0002,12.7639 L20.8222,16.4081 C21.1879,17.1395 20.6561,18 19.8384,18 L15.874,18 C15.4299,19.7252 13.8638,21 12,21 C10.1362,21 8.57006,19.7252 8.12602,18 L4.16197,18 C3.34425,18 2.8124,17.1395 3.1781,16.4081 L5.00016,12.7639 L5.00016,9 Z M10.2676,18 C10.6134,18.5978 11.2597,19 12,19 C12.7403,19 13.3866,18.5978 13.7324,18 L10.2676,18 Z M12.0002,4 C9.23874,4 7.00016,6.23858 7.00016,9 L7.00016,12.7639 C7.00016,13.0744 6.92787,13.3806 6.78902,13.6584 L5.6182,16 L18.3821,16 L17.2113,13.6584 C17.0725,13.3806 17.0002,13.0744 17.0002,12.7639 L17.0002,9 C17.0002,6.23858 14.7616,4 12.0002,4 Z' />
                        </g>
                    </svg>
                </v-badge>
            </router-link>
            <router-link to="/chats">
                <v-badge color="error" dot>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                        <title>message_4_line</title>
                        <g id="message_4_line" fill='none' fill-rule='nonzero'>
                            <path
                                d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z' />
                            <path fill='currentColor'
                                d='M19 3a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H7.333L4 21.5c-.824.618-2 .03-2-1V6a3 3 0 0 1 3-3h14Zm0 2H5a1 1 0 0 0-1 1v13l2.133-1.6a2 2 0 0 1 1.2-.4H19a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1Zm-8 7a1 1 0 0 1 .117 1.993L11 14H8a1 1 0 0 1-.117-1.993L8 12h3Zm5-4a1 1 0 1 1 0 2H8a1 1 0 0 1 0-2h8Z' />
                        </g>
                    </svg>
                </v-badge>
            </router-link>
        </div>
    </div>
</template>

<script>
import nav from '@/assets/js/nav'

export default
    {
        name: 'TopNav',
        data() {
            return {
                title: '',
                isScrolled: false, // New property to track scroll state
            };
        },
        mounted() {
            // Add a scroll event listener when the component is mounted
            window.addEventListener('scroll', this.checkScroll);
            if (document.querySelector('.title h1').textContent != null) {
                document.title = 'Rectrix Link - ' + document.querySelector('.title h1').textContent
            }
            this.checkScroll();
        },
        beforeUnmount() {
            // Remove the event listener when the component is about to be unmounted
            window.removeEventListener('scroll', this.checkScroll);
            document.title = 'Rectrix Link'
        },
        methods: {
            toggleNav() {
                nav.toggleNav();
            },
            checkScroll() {
                // Access the DOM element using this.$refs and check the scroll position
                const topNav = this.$refs.topNav;
                if (window.scrollY > 45) {
                    const title = document.querySelector('.title h1')
                    this.title = title.textContent
                    topNav.classList.add('scrolled');
                } else {
                    this.title = ''
                    topNav.classList.remove('scrolled');
                }
            }
        }
    }
</script>

<style scoped>
.top-nav {
    display: flex;
    justify-content: space-between;
    z-index: 998;
    position: sticky;
    top: 0;
    /* Ensure the nav sticks to the top */
    width: 100%;
    background: white;
    padding: 10px 40px 0px 40px;
    transition: border-bottom 0.3s;
    /* Smooth transition for the border */
}

.top-nav h3 {
    user-select: none;
    display: flex;
    align-items: center;
    transition: 1s all ease-in;
}

.top-nav.scrolled {
    border-bottom: 1px solid var(--secondary-dark-grey);
}


.top-nav .buttons {
    display: flex;
    align-items: center;
    gap: 25px;
}

.top-nav .buttons a {
    padding: unset;
}

.top-nav .buttons a:hover {
    all: unset;
    cursor: pointer;
    background-color: transparent !important;
}

@media screen and (max-width: 660px) {
    .top-nav {
        padding: 10px 20px 0px 20px;
    }
}
</style>