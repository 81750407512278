<template>
    <v-dialog v-model="isDialogOpen" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Assign Details</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">
                        <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-form ref="form">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <p>Available {{ this.currentRole === 'tour-guide' ? 'tour guide' : this.currentRole }}:
                                    {{ dtcData.length }}</p>
                                <p>Selected Transfers: {{ assignIds.length }}</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete :items="dtcData" item-title="full_name" item-value="uuid"
                                    label="Select Driver/Tour Guide" v-model="dtcId"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-btn class="button-1 w-100" :disabled="dtcId == '' || assignIds.length == 0"
                                    @click="submitRequest" :loading="isLoading">Request</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="returnTrip" max-width="400" persistent>
        <v-card prepend-icon="mdi-map-marker"
            :text="returnTripQueue.length > 0 ? returnTripQueue.length + ' return pick-ups detected. Do you want to select?' : 'Return pick-up detected. Do you want to select?'"
            title="Return Pick Up?">
            <template v-slot:actions>
                <v-spacer></v-spacer>

                <v-btn @click="this.returnTrip = false">
                    No
                </v-btn>

                <v-btn @click="returnTripQueue.length > 0 ? processReturnTrips() : assignReturnTrip()">
                    Yes
                </v-btn>
            </template>
        </v-card>
    </v-dialog>

    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <div class="information">
                    <h1>Calendar</h1>
                    <p>Dashboard > Calendar</p>
                </div>
                <v-btn variant="text" @click="toggleTicketsTable()"
                    :prepend-icon="showTicketsTable ? 'mdi-eye-off' : 'mdi-eye'">
                    {{ showTicketsTable ? 'Hide' : 'Show' }} Tickets Table
                </v-btn>
                <div class="date-control">
                    <v-btn density="compact" icon="mdi-arrow-left" @click="changeDate(-1)"></v-btn>
                    <VueDatePicker v-model="selectedDate" format="dd MMM yyyy" :enableTimePicker="false"
                        :style="{ width: 'fit-content' }" :clearable="false" @update:modelValue="selectDate" />
                    <v-btn density="compact" icon="mdi-arrow-right" @click="changeDate(1)"></v-btn>
                </div>
            </div>

            <div class="table-container" v-if="showTicketsTable">
                <table v-if="Object.keys(ticketsBreakdown).length > 0">
                    <thead>
                        <tr>
                            <th class="td-start">No.</th>
                            <th>Guest Name</th>
                            <th>Slug</th>
                            <th>Type</th>
                            <th>Adults</th>
                            <th>Children</th>
                            <th>Infants</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(guest, guestName, index) in ticketsBreakdown">
                            <tr>
                                <td class="td-start" :rowspan="Object.keys(guest).length + 1">{{ index + 1 }}.</td>
                                <td :rowspan="Object.keys(guest).length + 1">{{ guestName }}</td>
                            </tr>
                            <tr v-for="(info, slug) in guest" :key="slug">
                                <td>{{ slug }}</td>
                                <td>{{ info.name }}</td>
                                <td>{{ info.adults }}</td>
                                <td>{{ info.children }}</td>
                                <td>{{ info.infants }}</td>
                            </tr>
                        </template>
                        <tr>
                            <td colspan="4">Total</td>
                            <td>{{ Object.values(ticketsBreakdown).reduce((total, guest) => total +
        Object.values(guest).reduce((guestTotal,
            info) => guestTotal + info.adults, 0), 0) }}</td>
                            <td>{{ Object.values(ticketsBreakdown).reduce((total, guest) => total +
        Object.values(guest).reduce((guestTotal,
            info) => guestTotal + info.children, 0), 0) }}</td>
                            <td>{{ Object.values(ticketsBreakdown).reduce((total, guest) => total +
        Object.values(guest).reduce((guestTotal,
            info) => guestTotal + info.infants, 0), 0) }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="no-data" v-else>
                    <thead>
                        <tr>
                            <td class="td-start">There are currently no tickets breakdown for this day</td>
                        </tr>
                    </thead>
                </table>
            </div>

            <div class="transfer-container" v-else>
                <div class="transfer-header d-flex align-center">
                    <v-icon class="mr-3">mdi-calendar</v-icon>
                    <h2>{{ $moment(selectedDate).format('DD MMM yyyy') }}</h2>
                </div>
                <v-data-table v-if="transferData.length > 0" :headers="headers" :items="transferData"
                    :items-per-page="-1" :sort-by="[{ key: 'pick_up_time', order: 'asc' }]" disable-pagination
                    hide-default-footer item-key="uuid">
                    <template v-slot:item="{ item, index }">
                        <tr :key="item.uuid" @click="$router.push('/jobs/' + item.id)" class="location-details">
                            <td>{{ index + 1 }}.</td>
                            <td>
                                {{ $moment(item.pick_up_time).format('HH:mm') }}
                                <span v-if="item.priority !== null" class="current-badge">Priority</span>
                            </td>
                            <td>{{ `${item.tourist.first_name} ${item.tourist.last_name}` }}</td>
                            <td>{{ item.transfer_type }}</td>
                            <td>{{ item.transfer_type_special.transfer_type_special_slug }}</td>
                            <td>{{ item.from.main_text }}</td>
                            <td>{{ item.to.main_text }}</td>
                            <td>{{ item.remarks ?? '-' }}</td>
                            <td @click.stop>
                                <DriverAssignmentCard :is-priority-job="item.priority !== null" role="driver"
                                    :is-selected="assignIds.includes(item.uuid)" :current-role="currentRole"
                                    :assigned-job-request-details="item.assigned_job_request_details"
                                    :attendances="item.attendances ?? []"
                                    @assign="handleCheckboxClick(item.uuid, $event)" />
                            </td>
                            <td @click.stop>
                                <DriverAssignmentCard :is-priority-job="item.priority !== null" role="contractor"
                                    :is-selected="assignIds.includes(item.uuid)" :current-role="currentRole"
                                    :assigned-job-request-details="item.assigned_job_request_details"
                                    @assign="handleCheckboxClick(item.uuid, $event)" />
                            </td>
                            <td @click.stop>
                                <DriverAssignmentCard :is-priority-job="item.priority !== null" role="tour-guide"
                                    :is-selected="assignIds.includes(item.uuid)" :current-role="currentRole"
                                    :assigned-job-request-details="item.assigned_job_request_details"
                                    @assign="handleCheckboxClick(item.uuid, $event)" />
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                <div v-else class="transfer-detail">
                    <p>There are currently no jobs on this day</p>
                </div>
            </div>

            <div v-if="assignIds.length > 0" class="transfer-assign-shortcut d-flex align-center">
                <v-btn prepend-icon="mdi-close" color="error" @click="resetAssignIds()" class="mr-3">
                    Unselect
                </v-btn>
                <v-btn prepend-icon="mdi-check-circle-outline" class="button-1" @click="openDialog">
                    Assign Transfer
                </v-btn>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import DriverAssignmentCard from '@/components/AssignmentCard.vue';
import { httpPost } from '@/services/http';
import { toast } from 'vue3-toastify';
import db from '@/databases/indexedDB';
import { liveQuery } from 'dexie';
import { indexedDBService } from '@/services/indexedDBService';

export default {
    name: 'CalendarView',
    components: {
        TopNav,
        DriverAssignmentCard
    },
    data() {
        return {
            headers: [
                { title: 'No', key: 'index', sortable: false },
                { title: 'Time', key: 'pick_up_time', sortable: false },
                { title: 'Guest Name', key: 'tourist.first_name', sortable: false },
                { title: 'Transfer Type', key: 'transfer_type', sortable: false },
                { title: 'Slug', key: 'transfer_type_special.transfer_type_special_slug', sortable: false },
                { title: 'From', key: 'from.main_text', sortable: false },
                { title: 'To', key: 'to.main_text', sortable: false },
                { title: 'Remarks', key: 'remarks', sortable: false },
                { title: 'Driver', key: 'driver', sortable: false },
                { title: 'Contractor', key: 'contractor', sortable: false },
                { title: 'Tour Guide', key: 'tour_guide', sortable: false },
            ],
            currentRole: null,
            assignIds: [],
            dtcId: '',
            orgDTCData: [],
            dtcData: [],
            orgTransferData: [],
            transferData: [],
            selectedDate: new Date(),
            isShiftKeyPressed: false,
            returnTripQueue: [],
            returnTrip: false,
            isDialogOpen: false,
            showTicketsTable: false,
            isLoading: false,
            subscription: null,
        };
    },
    created() {
        this.init();
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyUp);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
    },
    beforeUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        init() {
            indexedDBService.getDtcsData();
            indexedDBService.getJobsData();
            indexedDBService.getAssignedJobRequestsData();
            this.fetchData();
            this.fetchDtcData();
        },
        fetchData() {
            const observableData = liveQuery(async () => {
                const jobs = await db.jobs.toArray();
                const transfers = await db.transfers.toArray();
                const users = await db.users.toArray();
                const transferTypes = await db.transfer_types.toArray();
                const locations = await db.locations.toArray();
                const locationDetails = await db.location_details.toArray();
                const assignedJobRequests = await db.assigned_job_requests.toArray();
                const assignedJobRequestDetails = await db.assigned_job_request_details.toArray();

                const jobMap = new Map(jobs.map(job => [job.uuid, job]));
                const userMap = new Map(users.map(user => [user.uuid, user]));
                const transferTypeMap = new Map(transferTypes.map(type => [type.uuid, type]));
                const locationMap = new Map(locations.map(location => [location.uuid, location]));
                const locationDetailMap = new Map(locationDetails.map(detail => [detail.uuid, detail]));
                const requestMap = new Map(assignedJobRequests.map(request => [request.uuid, request]));

                return transfers.map(transfer => {
                    const job = jobMap.get(transfer.job_uuid) || {};
                    const tourist = userMap.get(job.tourist_uuid) || {};
                    const transferTypeSpecial = transferTypeMap.get(transfer.transfer_type_special_uuid) || {};
                    const fromLocation = locationMap.get(transfer.from_uuid) || {};
                    const toLocation = locationMap.get(transfer.to_uuid) || {};
                    const fromLocationDetail = locationDetailMap.get(fromLocation.detail_uuid) || {};
                    const toLocationDetail = locationDetailMap.get(toLocation.detail_uuid) || {};

                    const assignedJobDetails = assignedJobRequestDetails
                        .filter(detail => detail.transfer_uuid === transfer.uuid)
                        .map(detail => {
                            const request = requestMap.get(detail.assigned_job_request_uuid) || {};
                            const requestUser = userMap.get(request.user_uuid) || {};
                            return { ...detail, assigned_job_request: { ...request, user: requestUser } };
                        });

                    return {
                        ...transfer,
                        tourist,
                        id: job.uuid,
                        no_of_adults: job.no_of_adults,
                        no_of_childs: job.no_of_childs,
                        no_of_infants: job.no_of_infants,
                        priority: job.priority,
                        transfer_type: transfer.transfer_type_name,
                        transfer_type_special: transferTypeSpecial,
                        from: { ...fromLocation, detail: fromLocationDetail },
                        to: { ...toLocation, detail: toLocationDetail },
                        assigned_job_request_details: assignedJobDetails
                    };
                });
            });

            this.subscription = observableData.subscribe(result => {
                this.orgTransferData = result;
                this.filterBySelectedDate();
            });
        },
        async computeTicketsBreakdown() {
            // Compute tickets breakdown grouped by tourist
            const ticketsBreakdown = this.transferData.reduce((acc, transfer) => {
                const guestName = `${transfer.tourist.first_name} ${transfer.tourist.last_name}`; // Assuming tourist object has first_name and last_name properties
                const slug = transfer.transfer_type_special.transfer_type_special_slug;
                const name = transfer.transfer_type_special.transfer_type_special_name;

                if (!acc[guestName]) {
                    acc[guestName] = {};
                }

                if (!acc[guestName][slug]) {
                    acc[guestName][slug] = {
                        name,
                        adults: transfer.no_of_adults,
                        children: transfer.no_of_childs,
                        infants: transfer.no_of_infants
                    };
                } else {
                    // Only count each slug once per guest
                    acc[guestName][slug].adults = Math.max(acc[guestName][slug].adults, transfer.no_of_adults);
                    acc[guestName][slug].children = Math.max(acc[guestName][slug].children, transfer.no_of_childs);
                    acc[guestName][slug].infants = Math.max(acc[guestName][slug].infants, transfer.no_of_infants);
                }

                return acc;
            }, {});

            this.ticketsBreakdown = ticketsBreakdown;
            console.log(this.ticketsBreakdown);
        },
        fetchDtcData() {
            const observableData = liveQuery(async () => {
                const dtcRequests = await db.dtc_requests.orderBy('created_at').reverse().toArray();
                const users = await db.users.toArray();
                const dtcRequestDetails = await db.dtc_request_details.toArray();

                const userMap = new Map(users.map(user => [user.uuid, user]));

                return dtcRequests.map(request => {
                    const requester = userMap.get(request.requester_uuid) || null;
                    const user = userMap.get(request.user_uuid) || null;
                    const details = dtcRequestDetails.filter(detail => detail.dtc_request_uuid === request.uuid);

                    return {
                        ...request,
                        requester,
                        user,
                        details,
                    };
                });
            });

            this.subscription = observableData.subscribe(result => {
                this.orgDTCData = result
                    .filter(a => a.status === 'Accepted' && !a.deleted_at)
                    .map(a => ({
                        ...a,
                        full_name: `${a.user.first_name} ${a.user.last_name} (${a.user.role.role_name})`
                    }));
                this.filterDTCData();
            });
        },
        filterDTCData() {
            if (this.currentRole) {
                this.dtcData = this.orgDTCData.filter(a => a.user.role.role_slug === this.currentRole);
            } else {
                this.dtcData = [...this.orgDTCData];
            }
        },
        submitRequest() {
            const data = { id: this.dtcId, transfer_ids: this.assignIds };
            const id = toast.loading('Please wait...');
            this.isLoading = true;
            httpPost('/api/v1/travel_agents/jobs/assigns/add', data, id)
                .then(() => {
                    this.dtcId = null;
                    this.currentRole = null;
                    this.assignIds = [];
                    toast.update(id, {
                        render: "Request submitted successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 3000
                    });
                    this.isLoading = false;
                    this.closeDialog();
                    this.init();
                })
                .catch(() => toast.remove(id));
        },
        selectDate(date) {
            this.selectedDate = new Date(date);
            this.filterBySelectedDate();
        },
        changeDate(step) {
            const newDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() + step));
            this.selectedDate = newDate;
            this.selectDate(newDate);
        },
        async filterBySelectedDate() {
            const selectedDateString = new Date(this.selectedDate).toDateString();
            console.log('Selected Date:', selectedDateString);

            const filteredTransfers = this.orgTransferData.filter(transfer => {
                const pickUpDate = new Date(transfer.pick_up_time).toDateString();
                return pickUpDate === selectedDateString;
            }).sort((a, b) => {
                const timeA = new Date(a.pick_up_time);
                const timeB = new Date(b.pick_up_time);
                return timeA - timeB;
            });

            this.transferData = filteredTransfers;
            await this.computeTicketsBreakdown();
            console.log('Filtered Transfers:', this.transferData);
        },
        handleCheckboxClick(transferId, role) {
            const index = this.assignIds.indexOf(transferId);
            if (index === -1) {
                this.assignIds.push(transferId);
                this.currentRole = role;
                this.filterDTCData();

                let selectedTransfer = this.transferData.find((a) => a.uuid === transferId);
                let returnTrip = this.transferData.find((a) =>
                    a.to.uuid === selectedTransfer.from.uuid &&
                    a.from.uuid === selectedTransfer.to.uuid &&
                    a.tourist.uuid === selectedTransfer.tourist.uuid &&
                    this.isSameDate(a.pick_up_time, selectedTransfer.pick_up_time)
                );

                if (returnTrip && !this.assignIds.includes(returnTrip.uuid)) {
                    if (this.isShiftKeyPressed) {
                        this.returnTripQueue.push(returnTrip);
                    } else {
                        this.returnTrip = returnTrip.uuid;
                    }
                }
            } else {
                this.assignIds.splice(index, 1);
                if (this.assignIds.length === 0) {
                    this.currentRole = null;
                    this.filterDTCData();
                }
            }
        },

        handleKeyDown(event) {
            if (event.key === 'Shift') {
                this.isShiftKeyPressed = true;
            }
        },

        handleKeyUp(event) {
            if (event.key === 'Shift') {
                this.isShiftKeyPressed = false;
                const unassignedReturnTrips = this.returnTripQueue.filter(trip => !this.assignIds.includes(trip.uuid));
                if (unassignedReturnTrips.length > 0) {
                    this.returnTrip = true; // Show dialog
                } else {
                    this.returnTripQueue = [];
                }
            }
        },
        processReturnTrips() {
            if (this.returnTripQueue.length > 0) {
                this.returnTripQueue.forEach(trip => {
                    this.assignIds.push(trip.uuid);
                });
                this.returnTripQueue = [];
            }
            this.returnTrip = false;
        },
        resetAssignIds() {
            this.assignIds = [];
            this.currentRole = null;
            this.filterDTCData();
        },

        isSameDate(date1, date2) {
            const d1 = new Date(date1);
            const d2 = new Date(date2);
            return (
                d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() &&
                d1.getDate() === d2.getDate()
            );
        },
        assignReturnTrip() {
            if (this.returnTrip) {
                this.assignIds.push(this.returnTrip);
                this.returnTrip = false;
            }
        },
        openDialog() {
            this.isDialogOpen = true;
        },
        closeDialog() {
            this.isDialogOpen = false;
        },
        toggleTicketsTable() {
            this.showTicketsTable = !this.showTicketsTable;
        }
    }
};
</script>

<style scoped>
.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date-control {
    display: flex;
    align-items: center;
    gap: 20px;
}

.date-control .v-btn {
    color: var(--primary-blue);
}

.transfer-container {
    margin-top: 20px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.transfer-header,
.transfer-info {
    padding: 20px;
    border-bottom: 1px solid #eee;
}

.transfer-header {
    background: var(--primary-blue);
    color: #ffffff;
    border-color: var(--primary-blue);
}

.transfer-header h2 {
    margin: 0;
}

.transfer-detail {
    display: flex;
    padding: 20px;
}

.transfer-detail>div {
    margin-right: 20px;
}

.transfer-detail .time,
.transfer-detail .transfer-type,
.transfer-detail .special-slug {
    margin-bottom: 10px;
}

.line-indicator {
    width: 4px;
    background-color: var(--primary-blue);
    border-radius: 2px;
}

.location-details {
    flex-grow: 1;
    cursor: pointer;
}

.line-indicator.grey {
    background-color: var(--secondary-dark-grey);
}

.transfer-detail:not(:first-child) {
    border-top: 2px solid var(--secondary-dark-grey);
}


.location-details .user .image-container {
    height: 35px;
    width: 35px;
    min-height: 35px;
    min-width: 35px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
}


.location-details .user .image-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.location-details .user {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--secondary-light-grey);
    border-radius: var(--border-radius);
    cursor: pointer;
    margin-bottom: 20px;
}

.location-info {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
    gap: 20px;
}

.location-details .location {
    text-align: center;
    width: 140px;
    max-width: 200px;
}

.location-path {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.line {
    flex-grow: 1;
    height: 2px;
    background-image: linear-gradient(to right, #000 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 2px;
    background-repeat: repeat-x;
}

.current-badge {
    display: block;
    background-color: var(--primary-blue);
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    margin-top: 5px;
}

/** Transfers **/
.transfer-summary {
    width: 80px;
}

.transfer-assign-shortcut {
    position: sticky;
    inset: 20px 20px;
    display: flex;
    justify-content: end;
}

.transfer-assign-shortcut .v-btn {
    background-color: var(--secondary-white);
    color: var(--secondary-black);
}
</style>

<style>
/** Checkbox assign **/
.v-checkbox .v-selection-control {
    justify-content: center;
}
</style>
