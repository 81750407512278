<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Driver and Tourist Locations</h1>
                <p>Dashboard > Maps > Driver Locations</p>
            </div>

            <div class="split-layout">
                <div class="map-container">
                    <div class="map-wrapper">
                        <GMapMap :center="center" :zoom="10" map-type-id="terrain" :options="{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: true,
                            disableDefaultUi: false,
                            styles: mapStyles
                        }">
                            <GMapMarker v-for="(driver, index) in drivers" :key="'driver-' + index"
                                :position="driver.position" :clickable="true" @click="toggleDriverInfo(driver, index)">
                                <GMapInfoWindow :opened="driver.infoWindowOpen"
                                    @closeclick="driver.infoWindowOpen = false">
                                    <div>
                                        <h3>{{ driver.name }}</h3>
                                        <p>Vehicle: {{ driver.vehicle }}</p>
                                        <h4>Tourists:</h4>
                                        <ul>
                                            <li v-for="(tourist, tIndex) in driver.tourists" :key="'tourist-' + tIndex">
                                                {{ tourist.name }} - {{ tourist.destination }}
                                            </li>
                                        </ul>
                                    </div>
                                </GMapInfoWindow>
                            </GMapMarker>
                        </GMapMap>
                    </div>
                </div>

                <div class="info-container">
                    <v-card elevation="0">
                        <v-tabs v-model="tab" color="primary">
                            <v-tab value="one">Your Drivers</v-tab>
                            <v-tab value="two">Today's Jobs</v-tab>
                        </v-tabs>

                        <v-card-text class="pa-0">
                            <v-window v-model="tab" class="mt-3">
                                <v-window-item value="one">
                                    <div class="drivers">
                                        <div v-for="(driver, index) in drivers" :key="'driver-list-' + index"
                                            class="driver" :class="{ 'selected': selectedDriver === driver }"
                                            @click="toggleDriverInfo(driver, index)">
                                            <div class="driver-details">
                                                <div class="image-container">
                                                    <v-avatar size="48">
                                                        <v-img :src="driver.imagePath" alt="Driver Image"></v-img>
                                                    </v-avatar>
                                                </div>
                                                <div class="details">
                                                    <p class="name">{{ driver.name }}</p>
                                                    <p>{{ driver.vehicle }}</p>
                                                </div>
                                            </div>
                                            <div class="trip-details">
                                                <span>
                                                    <v-icon>mdi-account-group</v-icon>
                                                    <p>{{ driver.tourists.length }}</p>
                                                </span>
                                                <span>
                                                    <v-icon>mdi-map-marker</v-icon>
                                                    <p>{{ driver.tourists.length }}</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </v-window-item>

                                <v-window-item value="two">
                                    <!-- Today's Jobs content remains unchanged -->
                                </v-window-item>
                            </v-window>
                        </v-card-text>
                    </v-card>

                    <!-- Detailed Driver Information -->
                    <v-expand-transition>
                        <v-card v-if="selectedDriver" class="mt-4">
                            <v-card-title>
                                {{ selectedDriver.name }}'s Current Trip
                            </v-card-title>
                            <v-card-text>
                                <p><strong>Last Updated:</strong> {{ selectedDriver.lastUpdated }}</p>
                                <p><strong>Vehicle:</strong> {{ selectedDriver.vehicle }}</p>
                                <v-divider class="my-3"></v-divider>
                                <h3>Guests:</h3>
                                <v-list>
                                    <v-list-item v-for="(tourist, index) in selectedDriver.tourists"
                                        :key="'tourist-' + index">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ tourist.name }}</v-list-item-title>
                                            <v-list-item-subtitle>
                                                Destination: {{ tourist.destination }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                Adults: {{ tourist.adults }}, Children: {{ tourist.children }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                Pick-up Time: {{ tourist.pickupTime }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-expand-transition>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import map from '@/assets/styles/map'
import TopNav from '@/components/TopNav.vue';

export default {
    name: 'MapView',
    components: {
        TopNav,
    },
    data() {
        return {
            mapStyles: JSON.parse(map.customMapStyles),
            center: { lat: 1.290270, lng: 103.851959 },
            tab: 'one',
            selectedDriver: null,
            drivers: [
                {
                    name: 'John Doe',
                    position: { lat: 1.290270, lng: 103.851959 },
                    vehicle: 'Toyota Prius',
                    infoWindowOpen: false,
                    imagePath: '/path/to/john-doe-image.jpg',
                    lastUpdated: '2023-09-09 14:30',
                    tourists: [
                        {
                            name: 'Alice Smith',
                            destination: 'Marina Bay Sands',
                            adults: 2,
                            children: 1,
                            pickupTime: '15:00'
                        },
                        {
                            name: 'Bob Johnson',
                            destination: 'Sentosa Island',
                            adults: 1,
                            children: 0,
                            pickupTime: '16:30'
                        }
                    ]
                },
                // Add more drivers as needed
            ],
            todaysJobs: []  // You'll need to populate this with actual data
        };
    },
    methods: {
        toggleDriverInfo(driver, index) {
            if (this.selectedDriver === driver) {
                // If the clicked driver is already selected, deselect it
                this.selectedDriver = null;
                driver.infoWindowOpen = false;
            } else {
                // Close info window for previously selected driver
                if (this.selectedDriver) {
                    this.selectedDriver.infoWindowOpen = false;
                }
                // Select the new driver
                this.selectedDriver = driver;
                driver.infoWindowOpen = true;
                this.centerMapOn(driver.position);
            }
        },
        centerMapOn(position) {
            this.center = position;
        }
    },
};
</script>

<style scoped>
.split-layout {
    display: flex;
    gap: 20px;
}

.map-container,
.info-container {
    flex: 1;
}

.map-wrapper {
    height: 500px;
}

.driver {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
}

.driver:hover,
.driver.selected {
    background-color: #f5f5f5;
}

.driver-details {
    display: flex;
    align-items: center;
    gap: 10px;
}

.details p {
    margin: 5px 0;
}

.trip-details {
    display: flex;
    gap: 15px;
}

.trip-details span {
    display: flex;
    align-items: center;
    gap: 5px;
}

.v-list-item-content {
    padding: 8px 0;
}

.v-list-item-title,
.v-list-item-subtitle {
    margin-bottom: 4px;
}
</style>